@import '@/theme.scss';

.wrap {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  .fileWrap {
    width: 300px;
    display: flex;
    flex-direction: column;
    height: 60px;
    margin-right: 10px;
    .fileWrap_label {
      height: 40px;
      line-height: 40px;
      margin: 0 20px 0 5px;
      display: flex;
      justify-content: space-between;
      .fileWrap_label_fileName {
        width: 200px;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &:hover {
          background-color: antiquewhite;
        }
      }
    }
  }

  .pending_fileWrap_label {
    width: 300px;
    margin: 0 20px 0 5px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pending_fileWrap_label_fileName {
      width: 200px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &:hover {
        background-color: antiquewhite;
      }
    }
  }
}

.previews {
  width: 100%;
  height: calc(100vh - 110px);
  display: flex;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  @include text-check-none();
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}