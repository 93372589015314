$Primary: #1890ff;
$Primarylight: #e6f7ff;

@mixin multi-line-ellipsis($num: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $num;
  -webkit-box-orient: vertical;
}

@mixin text-check-none() {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin fontMini {
	font-size: 12px;
}

@mixin scroll-bar($color1: $Primarylight, $color2: $Primary) {
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: $color1;
		border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $color2;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

// 水平
@mixin scroll-bar-horizontal() {
  &::-webkit-scrollbar {
		width: 0px;
		height: 8px;
  }
  @include scroll-bar();
}

// 垂直
@mixin scroll-bar-vertical() {
  &::-webkit-scrollbar {
		width: 8px;
		height: 0;
  }
  @include scroll-bar();
}

:global(.ant-table-cell) {
  word-break: break-all;
}

:global(.ant-btn-sm) {
	font-size: 12px;
}

:global(.ant-modal-wrap) {
  @include scroll-bar-vertical();
}

:global(.ant-modal-body) {
  @include scroll-bar-horizontal();
}

:global(.ant-table-content) {
  @include scroll-bar-horizontal();
}

:global(.ant-cascader-menu) {
  @include scroll-bar-vertical();
}

:global(.ant-drawer-body) {
  @include scroll-bar-vertical();
}

:global(.ant-table-body) {
  @include scroll-bar-horizontal();
}
