.container {
  width: 100%;
  height: 100%;
}

.loadingImg {
  display: none;
  width: 0;
  height: 0;
}

.none {
  width: 0;
  height: 0;
  overflow: hidden;
}
