@import '@/theme.scss';

.loginBox {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #f9f4eb;
  @include text-check-none();

  .loginBack {
    width: 900px;
    height: 550px;
    background: #f9f4eb;
    box-shadow: 0px 0px 200px 5px rgba(242, 134, 152, 0.6);
    display: flex;

    .loginImage {
      width: 460px;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .loginInfo {
      flex: 1;
      height: 100%;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;

      .loginIcon {
        width: 330px;
        height: auto;
        margin: 86px 0;
      }

      .formSty {
        width: 320px;
        margin: 0 35px;

        .tips {
          font-size: 12px;
          position: absolute;
          margin-top: -4px;
          margin-left: 44px;
        }

        .codes {
          position: absolute;
          z-index: 100;
          width: 136px;
          display: flex;
          height: 58px;
          overflow: hidden;
          margin: -16px 0 0 180px;
          cursor: pointer;
          background: #f4f4f4;
        }
      }

      .loginBtn {
        width: 320px;
        height: 52px;
        border-radius: 42px;
        border: none;
        display: flex;
        margin: 36px auto;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: linear-gradient(90deg, rgb(176, 14, 41), rgb(217, 94, 115));
      }
    }
  }

  .testBtn {
    width: 30px;
    height: 30px;
    position: fixed;
    right: 50px;
    bottom: 50px;
  }

  .spinBox {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    box-sizing: border-box;
  }
}
