@import '@/theme.scss';

.header {
  z-index: 100;
  height: 45px;
  line-height: 45px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  @include text-check-none();

  .logoBox {
    width: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .img1 {
      width: 18%;
      height: auto;
    }
    .img2 {
      width: 50%;
      height: auto;
    }
  }

  .dropdowna {
    width: auto;
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    .name {
      font-size: 14px;
      color: #181818;
      line-height: 1;

      &:first-of-type {
        margin-left: 8px;
      }
    }

    span {
      font-size: 14px;
    }
  }
}

.layoues {
  overflow: hidden;
  background: transparent;

  .sidersBox {
    background: #f5f5f5;
    overflow: hidden;
    padding: 12px 0;
    box-sizing: border-box;
    @include text-check-none();
  }

  .siders {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #fff;

    &::-webkit-scrollbar {
      width: 0;
    }

    :global(.ant-menu.ant-menu-dark) {
      background: #283039;
    }

    :global(.ant-menu-dark .ant-menu-inline.ant-menu-sub) {
      background: #1d2125;
    }

    :global(.ant-menu-dark .ant-menu-item-selected) {
      color: $Primary;
      background-color: #1d2125 !important;
      border-right: 5px solid $Primary;
    }

    :global(.ant-menu-dark .ant-menu-item-selected .anticon) {
      color: $Primary;

      & + span {
        color: $Primary;
      }
    }
  }

  .content {
    box-sizing: border-box;
    overflow-x: auto !important;
    border: 12px solid #f5f5f5;
    box-sizing: border-box;
    background: #f5f5f5;
    @include scroll-bar-horizontal();

    .body {
      min-width: 900px;
      min-height: 100%;
      padding: 12px;
      box-sizing: border-box;
      background: #fff;
    }

    :global(.ant-card) {
      margin-bottom: 12px;
      padding: 12px;
    }

    :global(.ant-card-bordered) {
      border: none;
    }

    :global(.ant-card-head) {
      border-bottom: none;
      padding: 0 0 12px 0;
    }

    :global(.ant-card-head-title) {
      padding: 10px 0;
    }

    :global(.ant-card-body) {
      padding: 0;
    }
  }
}
