@import './theme.scss';

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'PingFang-SC-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.2;
}
